<template>
    <div class="printModuleSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="bnts"
                @addClick="add()"
                @copyAddClick="capyAdd()"
                @renovateClick="DelOrVisible(0)"
                @startusingClick="DelOrVisible(1)"
                @deleteClick="DelOrVisible(2)"
                @refreshClick="loadData()"
                @goBackClick="back()"/>
            <div class="filter-type">
                <div class="bnt" :class="{selected:visible==-1}" @click="visible=-1">全部数据</div>
                <div class="bnt" :class="{selected:visible==1}" @click="visible=1">在用数据</div>
                <div class="bnt" :class="{selected:visible==0}" @click="visible=0">停用数据</div>
            </div>
        </div>
        <div class="data-box">
            <div class="table-box" ref="tableBox">
                <el-table style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                     :data="pageTableData"
                     highlight-current-row
                     @row-dblclick="dblclick"
                     @selection-change="handleSelectionChange"
                >
                    <el-table-column fixed label="序号" width="40" >
                        <template #default="scope">{{scope.$index+1+(tablePageIndex*pageSize)}}</template>
                    </el-table-column>
                    <el-table-column fixed type="selection" width="30"/>
                    <el-table-column prop="ISPUBLIC" fixed  label="模板属性" min-width="100">
                        <template #default="scope">
                            {{scope.row.ISPUBLIC?"默认公用模板":"门店私有模板"}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="MODULE_NAME" fixed  label="名称" min-width="100"></el-table-column>
                    <el-table-column prop="MODULE_KIND" label="类型" min-width="100">
                        <template #default="scope">
                            {{moduleKind[scope.row.MODULE_KIND]}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="REMARK" label="备注" min-width="120"></el-table-column>
                    <el-table-column prop="CREATER" label="创建人" width="100"></el-table-column>
                    <el-table-column prop="CREATE_TIME" label="创建时间" width="100"></el-table-column>
                    <el-table-column prop="LASTUPDATED_USER" label="最后更新人" width="100"></el-table-column>
                    <el-table-column prop="LASTUPDATED_TIME" label="最后更新时间" width="100"></el-table-column>
                    <el-table-column prop="" label="状态" width="80">
                        <template #default="scope">
                            {{scope.row.VISIBLE?"已启用":"已停用"}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template #default="scope" >
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <span>当前第 {{tablePageIndex+1}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{tablePageSum}}页</span>
                <div class="page-button">
                     <span class="but but-up" @click="billPage()">上一页</span>
                     <span class="but but-down"  @click="billPage(1)">下一页</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'printModuleSet',
    data(){
        return {
            tablePageIndex:0,
            pageSize:100,
            multipleSelection:[],
            tableData:[],
            visible:-1,
            moduleKind:{
                1:"消费清单",2:"结帐清单",4:"快餐结算单",6:"外送结帐单",8:"客看单",9:"结班单",10:"日结单",11:"配菜单",51:"办卡",52:"卡充值",
                801:"厨房单",810:"整桌催菜通知单",811:"整桌起菜通知单",812:"整桌等叫通知单",813:"整桌退菜通知单",820:"退菜通知单",821:"催菜通知单",
                822:"起菜通知单",823:"转菜通知单",830:"换台通知单",831:"并台通知单",832:"改席通知单",903:"押金单",53:"退卡单",
                9011:"AR清账打印模板",9012:"AR预付款打印模板",904:"核销券打印模版",905:"暂存单打印模版"
            }
        }
    },
    computed:{
        /**按钮 */
        bnts(){
            let data=['add','refresh','goBack'];
            if(this.multipleSelection?.length==1){
                data.push("copyAdd");
            }
            if(this.multipleSelection?.length>0){
                data.push("renovate");
                data.push("startusing")
                data.push("delete")
            }
            return data;
        },
        fileTableData(){
            if(this.visible==-1){
                return this.tableData||[];
            }
            return this.tableData?.filter(t=>t.VISIBLE==(this.visible==1))||[];
        },
        pageTableData(){
            return this.fileTableData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.fileTableData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        }
    },
    watch:{
        visible(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadData();
    },
    methods:{
        back(){
            this.$router.push("setup");
        },
         /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 40;
                    this.pageSize=parseInt(height/50);
                })
            }
        },
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.tableData=[];
            this.$httpAES.post("Bestech.CloudPos.GetPosPrintModuleInfoList",{}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableData=d.ResponseBody||[];
                    this.setTablePageSize()
                }else{
                    this.tableData=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                this.$message.error('加载打印模板数据失败：'+e);
                console.log('加载打印模板数据失败：'+e);
            })
        },
        handleSelectionChange (val){
            this.multipleSelection=val;
        },
        /**查看账单明细 */
        handleClick(row,isAdd) {
            this.$router.push({name: 'editPrintModuleSet', params:{id:row.MODULE_AUTOID,isAdd:isAdd,ISPUBLIC:isAdd?false:row.ISPUBLIC}});
        },
        /**双击 某一行*/
        dblclick(row, column){
            if(column.label!= "操作"){
                this.handleClick(row);
            }
        },
        /**新增 */
        add(){
            this.$router.push({name: 'editPrintModuleSet'});
        },
        /**复制新增 */
        capyAdd(){
            if(this.multipleSelection?.length>0){
                this.handleClick(this.multipleSelection[0],true)
            }
        },
        /**账单数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        //停用启用或删除
        DelOrVisible(type){
            let ids=[];
            let isBool=false;//是否存在勾选公有模板
            this.multipleSelection.forEach(row=>{
                if(row.ISPUBLIC){
                    isBool=true;
                    return;
                }
                ids.push(row.MODULE_AUTOID);
            })
            if(isBool){
                this.$message.warning("默认公用模板不可"+(type==0?"停用":(type==1?"启用":"删除")));
                return;
            }
            if(ids.length==0){
                this.$message.warning('请勾选需要操作的数据');
                return;
            }
            const loading = this.$loading({
                text: "数据修改中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DelOrVisiblePosPrintModuleInfo",{
                MODULE_AUTOIDLIST:ids,
                DelOrVisible:type,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据"+(type==0?"停用":(type==1?"启用":"删除"))+"成功");
                     this.loadData();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据"+(type==0?"停用":(type==1?"启用":"删除"))+'失败：'+e);
                console.log("数据"+(type==0?"停用":(type==1?"启用":"删除"))+'失败：'+e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './printModuleSet.scss'
</style>